import React from "react";
import ReactPlayer from "react-player"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import MultiCard from "../../components/MultiCard/MultiCard.js";

import styles from "../../assets/jss/material-dashboard-react/views/eventStyle.js";
const eventArticles = require('../../assets/articles/Event/EventArtiklar.json');

const useStyles = makeStyles(styles);

export default function Event() {
  const classes = useStyles();
  return (
    <GridContainer>
      <MultiCard source="Event/" jsonArray={eventArticles.articles} />
    </GridContainer>
  );
}
